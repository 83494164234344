<template>
  <div>
    <div class="text-right mb-1">
      <b-button to="/job-sheets/create" variant="outline-dark"
        >Create Job Sheet <feather-icon icon="PlusIcon"></feather-icon>
      </b-button>
    </div>
    <b-card title="Filters">
      <!-- <b-card-header>
        <div class="text-right">
          <b-button variant="outline-primary">
            <feather-icon icon="FilterIcon"></feather-icon> Show
            Filter</b-button
          >
        </div>
      </b-card-header> -->

      <div>
        <b-row>
          <b-col cols="3">
            <b-form-group label="Order ID" label-for="order-id-input">
              <v-select
                @search="fetchOrderByID"
                placeholder="Enter Order ID"
                v-model="filter.orderID"
                :options="ordersIDs"
                label="orderID"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Customer Phone"
              label-for="customer-phone-input"
            >
              <v-select
                @search="fetchCustomers"
                placeholder="Enter Customer Phone Number"
                v-model="filter.customer"
                :options="customerOptions"
                label="phone"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Brand" label-for="brand-input">
              <v-select
                @input="fetchModels"
                @search="fetchBrands"
                placeholder="Select Brand"
                v-model="filter.brand"
                :options="brands"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Model" label-for="model-input">
              <v-select
                @search="fetchModels"
                placeholder="Select Model"
                v-model="filter.model"
                :options="models"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Mode" label-for="mode-input">
              <v-select
                placeholder="Select Mode"
                v-model="filter.mode"
                :options="['Walk-in', 'Pickup & Drop', 'Doorstep']"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Repair Status" label-for="mode-input">
              <v-select
                placeholder="Select Repair Status"
                v-model="filter.status"
                :options="[
                  'Awaiting Device',
                  'Processed',
                  'In Progress',
                  'On Hold',
                  'Completed',
                  'Pick up scheduled',
                  'Picked Up',
                  'Delivered',
                  'Awaiting Courier',
                  'Courier Received',
                ]"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3"></b-col>
          <b-col cols="3">
            <b-button variant="primary" @click="getOrders">Search</b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <b-table
        :busy="loading"
        show-empty
        :items="leads"
        :fields="fields"
        striped
        responsive
        no-border-collapse
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading data...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <b-col cols="4" class="mx-auto my-2">
              <h4>No Data Found</h4>
              <feather-icon size="32" icon="AlertTriangleIcon"></feather-icon>
            </b-col>
          </div>
        </template>
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{
              row.detailsShowing ? "Hide" : "Show"
            }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1">
                <strong>Full Name : </strong>{{ row.item.user.name }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Mobile Number : </strong>{{ row.item.user.phone }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Total Amount : </strong>Rs
                {{ formatPrice(row.item.total) }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Brand : </strong>{{ row.item.brand.name }}
                <div class="mt-1">
                  <strong>Last Updated : </strong
                  >{{ getFormattedTime(row.item.updatedAt) }}
                </div>
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Model : </strong>{{ row.item.model.name }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Issues </strong>
                <div>
                  <b-badge
                    variant="light-primary"
                    :class="index != 0 ? 'ml-1' : ''"
                    v-for="(issue, index) in row.item.issues"
                    :key="issue._id"
                  >
                    {{ issue.issue.name }} - {{ formatPrice(issue.price) }}
                  </b-badge>
                </div>
              </b-col>
            </b-row>

            <b-button
              size="sm"
              :to="`/job-sheets/${row.item._id}`"
              variant="dark"
              @click="row.toggleDetails"
            >
              View More Details
            </b-button>

            <b-button class="ml-1" size="sm" variant="outline-success"
              >View Logs</b-button
            >
          </b-card>
        </template>

        <template #cell(order_id)="data">
          <b-badge variant="light-warning">
            {{ data.item.orderID }}
          </b-badge>
        </template>

        <template #cell(name)="data">
          <span class="text-nowrap">
            {{ data.item.user.name }}
          </span>
        </template>

        <template #cell(brand)="data">
          <span class="text-nowrap">
            {{ data.item.brand.name }}
          </span>
        </template>

        <template #cell(model)="data">
          <span class="text-nowrap">
            {{ data.item.model.name }}
          </span>
        </template>

        <template #cell(mode)="data">
          <span class="text-nowrap">
            {{ data.item.mode }}
          </span>
        </template>

        <template #cell(total)="data">
          <b-badge variant="light-primary">
            Rs {{ formatPrice(data.item.total) }}
          </b-badge>
        </template>

        <template #cell(action)="data">
          <b-button
            @click="markReabilityState(!data.item.isRead, data.item._id)"
            v-b-tooltip.hover.v-primary
            :title="data.item.isRead ? 'Mark Unread' : 'Mark Read'"
            variant="outline-primary"
            class="btn-icon rounded-circle"
          >
            <feather-icon
              :icon="data.item.isRead ? 'EyeOffIcon' : 'EyeIcon'"
            ></feather-icon>
          </b-button>

          <b-button
            :to="`/job-sheets/${data.item._id}`"
            v-b-tooltip.hover.v-dark
            title="View Job Sheet"
            variant="outline-dark"
            class="btn-icon rounded-circle mt-1"
          >
            <feather-icon icon="ArrowRightIcon"></feather-icon>
          </b-button>
        </template>

        <template #cell(created_at)="data">
          {{ getFormattedTime(data.item.createdAt) }}
        </template>
      </b-table>

      <b-card-footer v-if="leads.length">
        <b-pagination
          :per-page="perPage"
          v-model="page"
          :total-rows="rows"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BCardFooter,
  BPagination,
  VBTooltip,
  BFormGroup,
  BFormInput,
  BCardHeader,
  BSpinner,
} from "bootstrap-vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import moment from "moment-timezone";
import commaNumber from "comma-number";
import vSelect from "vue-select";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardFooter,
    BPagination,
    BFormGroup,
    BFormInput,
    vSelect,
    BCardHeader,
    BSpinner,
  },
  data() {
    return {
      fields: [
        "show_details",
        "order_id",
        "name",
        "brand",
        "model",
        "total",
        "mode",
        "status",
        "action",
      ],
      leads: [],
      page: 1,
      rows: 8,
      perPage: 15,
      customerOptions: [],
      brands: [],
      models: [],
      ordersIDs: [],
      filter: {
        orderID: "",
        customer: "",
        brand: "",
        model: "",
        mode: "",
        status: "",
      },
      loading: true,
    };
  },
  watch: {
    page() {
      this.getOrders();
    },
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getOrders() {
      this.loading = true;
      let queryParams = [];
      queryParams.push(`status=active`);
      queryParams.push(`page=${this.page}`);
      queryParams.push(`limit=${this.perPage}`);
      if (this.filter.orderID) {
        queryParams.push(`orderID=${this.filter.orderID._id}`);
      }
      if (this.filter.customer) {
        queryParams.push(`user=${this.filter.customer._id}`);
      }
      if (this.filter.brand) {
        queryParams.push(`brand=${this.filter.brand._id}`);
      }
      if (this.filter.model) {
        queryParams.push(`model=${this.filter.model._id}`);
      }
      if (this.filter.mode) {
        queryParams.push(`mode=${this.filter.mode}`);
      }
      if (this.filter.status) {
        queryParams.push(`order-status=${this.filter.status}`);
      }
      queryParams = queryParams.join("&");

      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/order?${queryParams}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.leads = response.data.leads;
          this.rows = response.data.count;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.loading = false;
        });
    },
    getFormattedTime(time) {
      return moment(time)
        .tz("Asia/Kolkata")
        .format("DD-MM-YYYY, h:mm a");
    },
    formatPrice(price) {
      return commaNumber(price);
    },
    markReabilityState(state, orderID) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/order/mark-readability-state`,
          {
            orderID,
            isRead: state,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let orderIndex = this.leads.findIndex((lead) => {
            return lead._id == orderID;
          });

          this.leads[orderIndex].isRead = state;
          this.makeToast("success", "Success", "The order has been updated!");
        })
        .catch((error) => {
          this.makeToast("danger", "Error", "Error updating the order!");
          console.log(error.response.data);
        });
    },
    fetchCustomers(phone, loading) {
      loading(true);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/user/search`,
          {
            phone,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.customerOptions = response.data;
          loading(false);
        })
        .catch((error) => {
          console.log(error.response.data);
          loading(false);
        });
    },
    fetchBrands(brand = "", loading) {
      if (loading) {
        loading(true);
      }
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/brand/get?brand=${brand}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.brands = response.data;
          if (loading) {
            loading(false);
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (loading) {
            loading(false);
          }
        });
    },
    fetchModels(model = "", loading) {
      if (loading) {
        loading(true);
      }
      let queryParams = [];
      queryParams.push(`model=${model}`);

      if (this.filter.brand) {
        queryParams.push(`brand=${this.filter.brand._id}`);
      }
      queryParams = queryParams.join("&");
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/model/get?${queryParams}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.models = response.data;
          if (loading) {
            loading(false);
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (loading) {
            loading(false);
          }
        });
    },
    fetchOrderByID(orderid, loading) {
      if (loading) {
        loading(true);
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/order?publicOrderID=${orderid}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.ordersIDs = response.data.leads;
          if (loading) {
            loading(false);
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (loading) {
            loading(false);
          }
        });
    },
  },
  mounted() {
    this.getOrders();
    this.fetchBrands();
    this.fetchModels();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
